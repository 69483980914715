(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  /*$.fancybox.open({
      src  : '/assets/ajax/reminding.html',
      type : 'ajax',
      opts : {
          afterShow : function( instance, current ) {
              console.info( 'done!' );
             // $(".content").mCustomScrollbar();
          }
      }
  });*/

  var accordion_wrapper = $('.brands-list .accordion__panel');
  accordion_wrapper.each(function () {
    $(this).find('.accordion__panel_title a').click(function () {
      var showCollapse = $(this).parents('.accordion__panel');
      if (showCollapse.attr('class') === 'accordion__panel opened') {
        $(this).parents('.accordion__panel').removeClass('opened');
      } else {
        accordion_wrapper.removeClass('opened');
        $(this).parents('.accordion__panel').addClass('opened');
      }
    });
  });

  /* var company_wrapper = $('.company-information .accordion__panel');
   company_wrapper.each(function () {
       $(this).find('.accordion__panel_title a').click(function () {
       
           console.log('aaaa');
       var showCollapse = $(this).parents('.accordion__panel');
       if (showCollapse.attr('class')==='accordion__panel opened') {
           $(this).parents('.accordion__panel').removeClass('opened');
       }else{
           company_wrapper.removeClass('opened');
           $(this).parents('.accordion__panel').addClass('opened');
       }
       });
   });*/

  $('.agreement-checkbox').find('input[type="checkbox"]').click(function () {
    if ($(this).is(':checked')) {
      $.fancybox.open({
        src: '/assets/ajax/agreement.html',
        type: 'ajax',
        opts: {
          afterShow: function afterShow(instance, current) {
            $(".content").mCustomScrollbar();
          }
        }
      });
    }
  });
  $('.agreement-event').click(function () {
    var thisSrc = $(this).data('src');
    $.fancybox.open({
      src: thisSrc,
      type: 'ajax',
      opts: {
        afterShow: function afterShow(instance, current) {
          $(".content").mCustomScrollbar();
        }
      }
    });
  });

  //homepage card mobile
  var x = false;
  $('.mobile-detail-button a').click(function (e) {
    e.preventDefault();
    var this_card_content = $(this).parents('.product_card').find('.product_card__content');
    var this_primaryText = $(this).data('primary');
    var this_secondaryText = $(this).data('secondary');
    if (!x) {
      $(this).html(this_secondaryText);
      x = true;
    } else {
      $(this).html(this_primaryText);
      x = false;
    }
    this_card_content.slideToggle();
  });

  /*$('.my_basket__toggle').click(function(e){
      e.preventDefault();
      $('.my_basket__content').slideToggle();
      $('.my_basket').toggleClass('off');
  });
   //mybasket button action
  $('.next-button').click(function(){
      $(this).hide();
      $('.basket-add-button').show();
      $('.card-type-select').hide();
      $('.person-information').show();
  });*/

  //pluxe
  $('.mobile-toggle-menu > a').click(function (e) {
    e.preventDefault();
    $('.backdrop').toggleClass('active');
    $(this).parents('.mobile-toggle-menu').toggleClass('active');
  });
  $('.backdrop').click(function (e) {
    e.preventDefault();
    $('.mobile-toggle-menu,.backdrop').removeClass('active');
  });
  var swiper = new Swiper(".pluxee-swiper", {
    speed: 900,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  });
});

},{}]},{},[1]);
